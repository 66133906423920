<template>
	<div class="mcontent">
		<div class="logo">
			<img class="img" src="../assets/img/mlogo.png" alt="app启动图标" />
		</div>
		<div class="title">雅正导游题库</div>
		<div class="ads">
			<p class="item">累计帮助全国</p>
			<p class="item big">10万+</p>
			<p class="item">考生顺利通过导游考试</p>
		</div>
		<div class="btn apple flex-row" @click="download('apple')">
			<my-icons type="apple" size="20" color="#5581ff"></my-icons>
			<span class="text">iphone下载</span>
		</div>
		<div class="btn android flex-row" @click="download('android')">
			<my-icons type="android" size="20" color="#ffffff"></my-icons>
			<!-- <span class="text">android下载</span> -->
			<a class="text" href="/apk/app-android-release.apk" download="">android下载</a>
		</div>
		<div class="adv">
			<img class="img" src="../assets/img/mads.png" alt="">
		</div>
		<div class="footer">
			<p>成都市雅正教育咨询有限公司旗下产品 </p>
			<p>客户服务热线：028-86116016</p>
			<p>Copyright 2014-2020  版权所有/    蜀ICP备14013155号-2 </p>
			<p>地址：成都市世界贸易中心A座1602</p>
		</div>
	</div>
</template>

<script>
import myIcons from '@/components/myIcons/myIcons.vue';
export default {
	data() {
		return {};
	},
	components: {
		'my-icons': myIcons
	},
	methods: {
		download(platform) {
			switch (platform) {
				case 'apple':
					alert('IOS应用正在努力的开发中。。。')
					break;
				case 'android':
					console.log('android');
					break;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.mcontent {
	display:flex;
	flex-flow:column nowrap;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	color:#fff;
	font-size:0.4rem;
	.logo{
		width:1.36rem;
		height:1.36rem;
		margin-top:0.9rem;
		margin-bottom:0.3rem;
	}
	.title {
		font-family: '楷体';
		font-size:0.42rem;
		letter-spacing: 0.02rem;
		font-weight: 600;
	}
	.ads{
		margin-top: 0.6rem;
		.item{
			font-size:0.36rem;
			margin-top: 0.4rem;
			&.big{
				font-size:0.56rem;
			}
		}
	}
	.btn{
		width:2.5rem;
		height:0.76rem;
		font-size:0.24rem;
		border-radius: 0.38rem;
		margin-top: 0.5rem;
		.text{
			margin-left:0.15rem;
		}
		&.apple{
			background-color: #FFFFFF;
			color:#5581ff;
		}
		&.android{
			border:1px solid #fff;
			color:#fff;
			background-color:#5581ff ;
		}
	}
	.adv{
		margin-top: 2.8rem;
		.img{
			width:100%;
		}
	}
	.footer {
		box-sizing: border-box;
		width:100%;
		padding: 0.1rem 0.2rem;
		color: #939393;
		font-size:0.14rem;
		text-align: center;
		background: #363b47;
		p{
			margin-bottom: 0.1rem;
		}
	}
}
</style>
