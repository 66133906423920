import Vue from 'vue'
import App from './App.vue'

// Vue.component('my-icons',require('./components/myIcons/myIcons.vue'))

Vue.config.productionTip = false

new Vue({
	render: h => h(App),
}).$mount('#app')

// window.onresize = function() {
// 	new Vue({
// 		render: h => h(App),
// 	}).$mount('#app')
// }
