<template>
	<header class="header ">
		<div class="content flex-row">
			<div class="left flex-row">
				<img class="logo" src="../assets/img/logo.png" alt="logo" />
				<h1 class="name">雅正导游考试在线题库App</h1>
			</div>
			<div class="right flex-row">
				<div class="mobile flex-row">
					<a href="#">
						<my-icons type="mobile" size="24" color="#ffffff"></my-icons>
						<span>手机版</span>
					</a>
				</div>
				<div class="btn flex-row">
					<div class="btn-item login flex-col current"><a href="https://www.daoyoupeixun.cn/Login/index.html">登录</a></div>
					<div class="btn-item reg flex-col"><a href="https://www.daoyoupeixun.cn/Login/reg.html">注册</a></div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import myIcons from '@/components/myIcons/myIcons.vue';
export default {
	data() {
		return {};
	},
	components: {
		'my-icons': myIcons
	}
};
</script>

<style lang="less" scoped>
@import '@/theme.less';
.header {
	// background-color: @theme;
	background-color: transparent;
	// background-image: linear-gradient( 90deg, rgb(91,161,253) 0%, rgb(113,113,254) 100%);
	.content {
		width: 1200px;
		height: 60px;
		// border-bottom: 1px solid #ccc;
		justify-content: space-between;
		.left {
			width: 450px;
			height: 80%;
			// background-color: pink;
			justify-content: flex-start;
			.logo {
				margin-right: 15px;
			}
			.name {
				font-weight: normal;
				font-size: 26px;
				color: #fff;
				letter-spacing: 1px;
			}
		}
		.right {
			height: inherit;
			flex: 1;
			justify-content: flex-end;
			color: #fff;
			font-size: 20px;
			font-weight: normal;

			.mobile {
				height: inherit;
				// border-radius: 60px;
				box-sizing: border-box;
				margin-right: 40px;
				padding: 0 15px;
				border-bottom: 2px solid #fff;
				// background-color: #FFFFFF;
				color: #fff;
				// color:@purple;
			}
			.btn {
				height: inherit;
				.btn-item {
					height: 70%;
					padding: 0 25px;
					border-radius: 30px;
					transition: all ease 0.5s;
					margin-left: 30px;
					&:hover,
					&.currrent {
						background-color: #fff;
						color: @purple;
					}
				}
				// .login{
				// 	background-color: #fff;
				// 	color:@theme;
				// }
			}
		}
	}
}
</style>
