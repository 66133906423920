<template>
	<div class="content main flex-row">
		<div class="left">
			<!-- <div class="title">导游考试在线题库</div> -->
			<div class="introduce" style="text-align:left">
				为每一个导游考生保驾护航
			</div>
			<ul class="adv">
				<li class="item">● 考导游</li>
				<li class="item">● 刷题库</li>
				<li class="item">● 轻松过关</li>
				<li class="item">● 专业师资</li>
				<li class="item">● 专业题库</li>
				<li class="item">● 专业管理</li>
				<li class="item">● 专业售后</li>
			</ul>              
			<div class="download flex-row">
				<div class="ewm">
					<img class="img" src="../assets/img/ewm.png">
				</div>
				<div class="btn-group flex-col" >
					<div class="btn apple flex-row" @click="download('apple')">
						<my-icons type="apple" size="36" color="#fff"></my-icons>
						<span class="text">免费下载</span>
					</div>
					<div class="btn android flex-row">
						<my-icons type="android" size="36" color="#fff"></my-icons>
						<!-- <span class="text">免费下载</span> -->
						<a class="text" href="/apk/app-android-release.apk" download="">免费下载</a>
					</div>
				</div>
			</div>
			<div class="tips">累计帮助全国10万+考生顺利通过考试</div>
		</div>
		<div class="right">
			<img src="../assets/img/iphone.png">
		</div>
	</div>
</template>


<script>
	import myIcons from '@/components/myIcons/myIcons.vue';
	export default {
		data() {
			return {};
		},
		components: {
			'my-icons': myIcons
		},
		methods:{
			download(platform) {
				switch(platform) {
					case 'apple':
						alert('IOS应用正在努力的开发中。。。')
					break;
					case 'android':
						console.log('android');
					break;
				}
			}
		}
	};
</script>

<style lang="less" scoped>
	.main{
		justify-content: flex-end;
		.left{
			margin-top: 60px;
			width:692px;
			text-align: center;
			align-self: flex-start;
			color:#fff;
			font-size:32px;
			line-height: 3em;
			.adv{
				display:flex;
				flex-flow:row wrap;
				.item {
					margin:0 15px;
				}
			}
			.download{
				font-size:24px;
				margin-top: 70px;
				margin-bottom:50px;
				height:200px;
				.btn-group{
					margin-right: 40px;
					justify-content: space-around;
					height:inherit;
					.btn{
						width:188px;
						height:64px;
						border-radius: 12px;
						cursor: pointer;
						// margin-bottom:30px;
						.text{
							margin-left:15px;
						}
						&.apple{
							background-color: #1a95e2;
						}
						&.android{
							background-color: #66ca2a;
						}
					}
				}
				.ewm {
					max-width:200px;
					margin-right:60px;
					align-self: flex-start;
					.img{
						width:100%;
					}
				}
				
			}
			.tips{
				text-align:left;
				font-size:24px;
			}
		}
		
		.right{
			width:508px;
			margin-top: 60px;
		}
	}
</style>
