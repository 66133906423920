<template>
	<span :style="{ color: color, 'font-size': size + 'px' }" class="my-icons">
		{{ icons[type] }}
	</span>
</template>

<script>
import icons from './icons';
export default {
	name: 'myIcons',
	props: {
		type: {
			type: String,
			default: ''
		},
		color: {
			type: String,
			default: '#55c5ff'
		},
		size: {
			type: [Number, String],
			default: 16
		}
	},
	data() {
		return {
			icons: icons
		};
	}
};
</script>

<style>
@font-face {
	font-family: my-icons;
  src: url('//at.alicdn.com/t/font_1894320_hb8z5cvxnsn.eot');
  src: url('//at.alicdn.com/t/font_1894320_hb8z5cvxnsn.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_1894320_hb8z5cvxnsn.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_1894320_hb8z5cvxnsn.woff') format('woff'),
  url('//at.alicdn.com/t/font_1894320_hb8z5cvxnsn.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_1894320_hb8z5cvxnsn.svg#my-uni') format('svg');
}
.my-icons {
	font-family: 'my-icons' !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
