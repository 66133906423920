<template>
	<div id="app">
		<!-- <img alt="Vue logo" src="./assets/logo.png"> -->
		<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

		<div v-if="sw > 750"><wIndex></wIndex></div>
		<div v-else><mIndex></mIndex></div>
	</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import wIndex from './views/windex.vue';
import mIndex from './views/mindex.vue';

export default {
	name: 'App',
	data() {
		return {
			sw: null
		};
	},
	components: {
		wIndex,
		mIndex
	},
	// created() {
	// 	// console.log('app')
	// 	let sw = document.documentElement.offsetWidth || document.body.offsetWidth;
	// 	this.sw = sw;
	// 	console.log(sw);
	// },
	mounted() {
		const that = this
		function setDocumentRem() {
			const html = document.documentElement;
			const wW = html.offsetWidth; // 窗口宽度
			that.sw = wW
			console.log(wW);
			const rem = (wW * 100) / 750; // 基准值
			document.documentElement.style.fontSize = rem + 'px';
		}
		// 初始化
		setDocumentRem();
		// 改变窗口大小时重新设置 rem
		window.onresize = function() {
			setDocumentRem();
		};
	}
};
</script>

<style lang="less">
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
@import 'assets/css/common.css';
@import './theme.less';
html,
body {
	height: 100%;
}
#app {
	height: 100%;
	width: 100%;
	background-image: url('assets/img/bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	.content {
		max-width: 1200px;
		margin: 0 auto;
	}
	.mcontent{
		width:100%;
	}
}
@media (max-width: 750px) {
	#app {
		background-image: url('assets/img/mbg.png');
	}
}
</style>
