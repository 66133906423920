<template>
	<div>
		<wHeader></wHeader>
		<wMain></wMain>
		<wFooter></wFooter>
	</div>
	
</template>

<script>
import wHeader from '../components/wHeader.vue';
import wMain from '../components/wMain.vue';
import wFooter from '../components/wFooter.vue';
export default {
	data() {
		return {};
	},
	components: {
		wHeader,
		wMain,
		wFooter
	}
};
</script>

<style lang="less" scoped></style>
