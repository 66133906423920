export default {
	"zjlx": '\ue609',
	"cuotiku": "\ue628",
	"keshilianxi": "\ue631",
	"zujuan": "\ue65f",
	"yati": "\ue60d",
	"shoucang": "\ue78c",
	"shoucang-fill": "\ue694",
	"laba": "\ue6bb",
	"menu": "\ue64f",
	"hands": "\ue693",
	"time": "\ue600",
	"jinggao": "\ue604",
	"error": "\ue64a",
	// "right": "\ue62c",
	"tip": "\ue630",
	"setting": "\ue611",
	"pub": "\ue632",
	"shuxian": "\ue638",
	"jiaocai": "\ue613",
	"shop": "\ue620",
	"video": "\ue647",
	"tiku": "\ue69b",
	"my": "\ue612",
	"more": "\ue656",
	"view": "\ue60a",
	// "course": "\ue661",
	"paper": "\ue62e",
	"course-bs": "\ue61c",
	"course-ks": "\ue6b2",
	"course": "\ue60b",
	"video1": "\ue66b",
	"video2": "\ue60e",
	"comment": "\ue891",
	"play": "\ue737",
	"dayi": "\ue622",
	"note": "\ue683",
	"qCard": "\ue662",
	"back": "\ue60c",
	"pen": "\ue610",
	"done": "\ue623",
	"right": "\ue678",
	"wrong": "\ue626",
	"time-fill": "\ue77c",
	"user": "\ue634",
	"tel": "\ue61f",
	"location": "\ue629",
	"lock": "\ue6b1",
	"yzm": "\ue6bf",
	"school": "\ue62d",
	"info": "\ue7ad",
	"shanchu":"\ue614",
	"empty":"\ue708",
	"upload":"\ue663",
	"notice":"\ue639",
	"curve":"\ue60f",
	"photo":"\ue616",
	"camera":"\ue625",
	"pulldown":"\ue617",
	"group":"\ue64b",
	"money":"\ue605",
	"qrCode":"\ue618",
	"fenxiao":"\ue619",
	"ticheng":"\ue7de",
	"mobile":"\ue650",
	"apple":"\ue635",
	"android":"\ue677"
}
